@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;600;700&display=swap");

* {
  font-family: "Montserrat", sans-serif !important;
  padding: 0px;
  margin: 0px;
  outline: none;
  text-decoration: none;
  box-sizing: border-box;
  font-weight: 300;
  color: #181122;
}

body,
html {
  width: 100%;
  height: 100%;
  background: #fafafa;
  overflow-x: visible;
}

strong {
  font-weight: 700;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  40% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

.MuiDataGrid-overlay {
  color: #fff;
}

.hover .MuiDataGrid-row {
  cursor: pointer;
}

.MuiDataGrid-row.Mui-selected {
  background-color: #e4e4e4 !important;
}
